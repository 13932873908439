import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import Footer from "./Footer";

const Splash = () => {
	const history = useHistory();

	const [search, setSearch] = useState("");
	const [searchList, setSearchList] = useState([]);
	const [searchFiltered, setSearchFiltered] = useState([]);
	const [error, setError] = useState();
	const [placeholder, setPlaceholder] = useState(
		"Enter an address, city, or ZIP code"
	);

	const searchDivRef = useRef();
	const searchDDRef = useRef();

	const directSearch = (term) => {
		setError("");
		const searchTerm = term.split(" ").join("-");
		history.push(`/search/${searchTerm}`);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (search.length > 0) {
			setError("");
			const searchTerm = search.split(" ").join("-");
			history.push(`/search/${searchTerm}`);
		} else {
			setError("Please enter address, city, or zip code to begin");
		}
	};

	const goToAgentSearch = async (e) => {
		e.preventDefault();
		// use history to redirect
		history.push(`/Agents`);
	};

	useEffect(() => {
		fetch("/api/search/terms")
			.then((res) => res.json())
			.then((res) => setSearchList(res.terms))
			.catch((err) => console.log(err));
	}, []);

	useEffect(() => {
		const filtered = searchList.filter((term) =>
			term.toLowerCase().includes(search.toLowerCase())
		);
		setSearchFiltered(filtered);
	}, [search, searchList]);

	return (
		<>
			<main className="splash-ctrl">
				<form className="splash-search-wrap" onSubmit={handleSubmit}>
					<div className="splash-search-title">Find a great affordable agent</div>
					<div className="splash-search-subtitle">Finding the perfect home starts with finding an experienced local agent. 1percentors was created with this in mind.  All of the agents featured here are committed to assisting their clients throughout their real estate journey for a flat fee of a 1% commission.</div>
					<div className="splash-search-subtitle">
							<button
								type="button"
								className="splash-agent-button"
								onClick={goToAgentSearch}
							>
								Connect with a local agent
							</button>
					</div>
				</form>
			</main>
			<Footer />
		</>
	);
};

export default Splash;

import ReactTooltip from "react-tooltip";

import Kathleen from "../../assets/kathleen/Kathleen_500_500.png";
import git from "../../assets/about/git-logo.png";
import linkedin from "../../assets/about/linkedin-logo.png";
import skyline from "../../assets/about/skyline.png";

const About = () => {
	return (
		<div
			className="about-ctrl"
			style={{ backgroundImage: `url("${skyline}")` }}
		>
			<ReactTooltip />
			<div className="wrapper">
				<img className="img" src={Kathleen} alt="Kathleen" />
				<div>
					<div className="name">Kathleen Gallagher-Mody</div>
					<div className="slogan">Owner/Broker, Kathleen Gallagher-Mody has over 25 years experience in the field of residential property management, leasing, and sales.  Kathleen  pursued her BBA in Finance from UT Arlington, and shortly after graduating became one of DFWs youngest Real Estate Brokers. Having grown up around very successful Real Estate Brokers and investors in her family, pursuing a career in this industry felt like second nature. Kathleen also holds a California Brokers license.</div>
				</div>
				<div className="icon-group">
					<a
						href="https://www.kathleengallagherrealty.com/"
						target="_blank"
						rel="noreferrer"
						data-tip="Portfolio"
					>
						<i className="fa-solid fa-briefcase icon"></i>
					</a>
					<a
						href="https://www.linkedin.com/in/kathleen-gallagher-mody-002b1516b/"
						target="_blank"
						rel="noreferrer"
						data-tip="LinkedIn"
					>
						<img src={linkedin} className="icon" alt="Linked In" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default About;
